import AceEditor from "react-ace"

import "ace-builds/src-noconflict/ext-searchbox"
import "ace-builds/src-noconflict/theme-textmate"
import "ace-builds/src-noconflict/mode-javascript"
import "ace-builds/src-noconflict/mode-json"
import "ace-builds/src-noconflict/mode-xml"
import "ace-builds/src-noconflict/mode-text"
import "ace-builds/src-noconflict/mode-html"
import "ace-builds/src-noconflict/mode-css"
import "ace-builds/src-noconflict/mode-groovy"
import "ace-builds/src-noconflict/mode-markdown"
import "ace-builds/src-noconflict/mode-sql"

import javascriptWorkerUrl from "file-loader!ace-builds/src-min-noconflict/worker-javascript"
import jsonWorkerUrl from "file-loader!ace-builds/src-min-noconflict/worker-json"
import xmlWorkerUrl from "file-loader!ace-builds/src-min-noconflict/worker-xml"
import htmlWorkerUrl from "file-loader!ace-builds/src-min-noconflict/worker-html"
import cssWorkerUrl from "file-loader!ace-builds/src-min-noconflict/worker-css"

ace.config.setModuleUrl("ace/mode/javascript_worker", javascriptWorkerUrl)
ace.config.setModuleUrl("ace/mode/json_worker", jsonWorkerUrl)
ace.config.setModuleUrl("ace/mode/xml_worker", xmlWorkerUrl)
ace.config.setModuleUrl("ace/mode/html_worker", htmlWorkerUrl)
ace.config.setModuleUrl("ace/mode/css_worker", cssWorkerUrl)

class CodeEditor extends React.Component {

  onChange = (newVal) => {
    this.props.handleTextChange({target: {value: newVal}})
  }

  parseIntOrUndefined(value) {
    let res = parseInt(value)
    return isNaN(res) ? undefined : res 
  }

  render() {
    let field = this.props.data
    let height =  this.parseIntOrUndefined(MC.getFieldParamValue(field.param, '@height')) || 500
    let minLines =  MC.getFieldParamValue(field.param, '@minLines')
    let maxLines =  MC.getFieldParamValue(field.param, '@maxLines')
    if (MC.getFieldParamBooleanValue(field.param, '@fitToContent')) {
      maxLines = Infinity
    }
    let contentType =  MC.getFieldParamValue(field.param, '@contentType')
    let mode = 'text'
    if (contentType && typeof contentType == 'string') {
      for (let item of ['html', 'css', 'xml', 'json', 'javascript', 'groovy', 'markdown', 'sql']) {
        if (contentType.endsWith(item)) {
          mode = item
          break
        }
      } 
    }
    let wrap =  MC.getFieldParamBooleanValue(field.param, '@wrap')
    let hideGutter = MC.getFieldParamBooleanValue(field.param, '@hideGutter')
    let showPrintMargin = MC.getFieldParamBooleanValue(field.param, '@showPrintMargin')
    let options = {}
    if (showPrintMargin) {
      options.printMarginColumn = MC.getFieldParamValue(field.param, '@printMarginSize') || 80
    }
    return <AceEditor mode={mode} theme="textmate" onChange={this.onChange} name={this.props.data.id} placeholder={this.props.placeholder} readOnly={this.props.readOnly || this.props.disabled}
            width="100%" height={height + 'px'} value={this.props.value} tabSize={2} wrapEnabled={wrap} minLines={minLines} maxLines={maxLines} showGutter={!hideGutter} showPrintMargin={showPrintMargin}
            setOptions={options} className={MC.getFieldParamValue(field.param, '@cssClass')} style={MC.styleObjectFromString(MC.getFieldParamValue(field.param, '@css'))}/>
  }  

}

MC.registerReactRomponent('codeeditor', CodeEditor)